export const products = [
    {
      id: 1,
      img: require('./assets/keurig.png'),
      link: "https://in-info-web4.informatics.iupui.edu/~rsjat/n315/final/",
      description: "Keurig Web"
    },
    {
      id: 2,
      img: require('./assets/junglecook.png'),
      link: "https://in-info-web4.informatics.iupui.edu/~rsjat/n315/Jungle%20Cook/browse.html",
    },
    {
      id: 3,
      img: require('./assets/travel.png'),
      link: "https://in-info-web4.informatics.iupui.edu/~rsjat/homework-ten/",
    },
    {
      id: 4,
      img: require('./assets/tictactoe.png'),
      link: "https://in-info-web4.informatics.iupui.edu/~rsjat/homework-ten/",
    },
    {
      id: 5,
      img: require('./assets/music.png'),
      link: "https://codesandbox.io/s/relaxed-noyce-61980",
    },
    {
      id: 6,
      img: require('./assets/eventsy.png'),
      link: "https://in-info-web4.informatics.iupui.edu/~rsjat/final-project/",
    },
  ];