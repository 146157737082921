import React, { useContext } from "react";
import { useState } from "react";
import './skills.css';
// import { FaAngleDown } from 'react-icons/fa';
import { IoServerOutline } from 'react-icons/io5';
import { BiCodeCurly } from 'react-icons/bi';
import { HiOutlineColorSwatch } from 'react-icons/hi';
import { ThemeContext } from "../../context"

const Skills = () => {
    const [show1, setShow1] = useState(true);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    // const handleClick = (component) => {
    //     setShow1(false);
    //     setShow2(false);
    //     setShow3(false);
    //     if (component === 'Component1') {
    //         setShow1(true);
    //     } else if (component === 'Component2') {
    //         setShow2(true);
    //     } else if (component === 'Component3') {
    //         setShow3(true);
    //     }
    // }
    function Component1() {
        setShow1(!show1)
        setShow2(false)
        setShow3(false)
    }
    function Component2() {
        setShow2(!show2)
        setShow1(false)
        setShow3(false)
    }
    function Component3() {
        setShow3(!show3)
        setShow2(false)
        setShow1(false)
    }

    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    // const ShowSkills = () => {
    //     show ? setShow(false) : setShow(true);
    //     console.log(show);
    // }
    return (
        <section id="skills" className="skills section">
            <h2 className="section__title">Skills</h2>
            {/* <spam className="section__subtitle">My Technical level</spam> */}

            <div className="skills__container container grid">
                <div>
                    <div className="skills__content skills__open">
                        <div className="skills__header">
                            {/* <i className="uil uil-brackets-curly skills__icon"></i> */}
                            <BiCodeCurly className="skills__icon" />

                            <div>
                                <h1 className="skills__titles" style={{
                                    color: darkMode ? "white" : "black",

                                }}>Frontend</h1>
                            </div>
                            {/* <FaAngleDown className="skills__arrow" size={40} color={"#6e57e0"} onClick={Handlechange} /> */}
                            <button className="skills__arrow" onClick={() => Component1()}>{show1 ? <i className="uil uil-angle-up" /> : <i className="uil uil-angle-down skills__arrow" />}</button>
                        </div>
                        {
                            show1 && (<div>

                                <div className="skills__list grid">
                                    <div className="skills__data">
                                        <div className="skills__titles">
                                            <h3 className="skills__name" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>HTML</h3>
                                            <span className="skills__number" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>90%</span>
                                        </div>
                                        <div className="skills__bar">
                                            <span className="skills_percentage skills__html"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="skills__list grid">
                                    <div className="skills__data">
                                        <div className="skills__titles">
                                            <h3 className="skills__name" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>CSS</h3>
                                            <span className="skills__number" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>90%</span>
                                        </div>
                                        <div className="skills__bar">
                                            <span className="skills_percentage skills__css"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="skills__list grid">
                                    <div className="skills__data">
                                        <div className="skills__titles">
                                            <h3 className="skills__name" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>JavaScript</h3>
                                            <span className="skills__number" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>85%</span>
                                        </div>
                                        <div className="skills__bar">
                                            <span className="skills_percentage skills__js"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="skills__list grid">
                                    <div className="skills__data">
                                        <div className="skills__titles">
                                            <h3 className="skills__name" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>REACT</h3>
                                            <span className="skills__number" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>80%</span>
                                        </div>
                                        <div className="skills__bar">
                                            <span className="skills_percentage skills__react"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>)
                        }


                    </div>

                    <div className="skills__content skills__open">
                        <div className="skills__header">
                            {/* <i className="uil uil-brackets-curly skills__icon"></i> */}
                            <IoServerOutline className="skills__icon" />

                            <div>
                                <h1 className="skills__titles" style={{
                                    color: darkMode ? "white" : "black",

                                }}>Backend</h1>
                            </div>
                            {/* <FaAngleDown className="skills__arrow" size={40} color={"#6e57e0"}/> */}
                            <button className="skills__arrow" onClick={() => Component2()}>{show2 ? <i className="uil uil-angle-up" /> : <i className="uil uil-angle-down skills__arrow" />}</button>
                        </div>
                        {
                            show2 && (<div>

                                <div className="skills__list grid">
                                    <div className="skills__data">
                                        <div className="skills__titles">
                                            <h3 className="skills__name" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>PHP</h3>
                                            <span className="skills__number" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>55%</span>
                                        </div>
                                        <div className="skills__bar">
                                            <span className="skills_percentage skills__php"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="skills__list grid">
                                    <div className="skills__data">
                                        <div className="skills__titles">
                                            <h3 className="skills__name" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>NODE JS</h3>
                                            <span className="skills__number" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>65%</span>
                                        </div>
                                        <div className="skills__bar">
                                            <span className="skills_percentage skills__node"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="skills__list grid">
                                    <div className="skills__data">
                                        <div className="skills__titles">
                                            <h3 className="skills__name" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>Python</h3>
                                            <span className="skills__number" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>25%</span>
                                        </div>
                                        <div className="skills__bar">
                                            <span className="skills_percentage skills__python"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="skills__list grid">
                                    <div className="skills__data">
                                        <div className="skills__titles">
                                            <h3 className="skills__name" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>MySQL</h3>
                                            <span className="skills__number" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>55%</span>
                                        </div>
                                        <div className="skills__bar">
                                            <span className="skills_percentage skills__sql"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>)


                        }
                    </div>

                    <div className="skills__content skills__open">
                        <div className="skills__header">
                            {/* <i className="uil uil-brackets-curly skills__icon"></i> */}
                            <HiOutlineColorSwatch className="skills__icon" />

                            <div>
                                <h1 className="skills__titles" style={{
                                    color: darkMode ? "white" : "black",

                                }}>UI/UX</h1>
                            </div>
                            {/* <FaAngleDown className="skills__arrow" size={40} color={"#6e57e0"}/> */}
                            <button className="skills__arrow" onClick={() => Component3()}>{show3 ? <i className="uil uil-angle-up" /> : <i className="uil uil-angle-down skills__arrow" />}</button>
                        </div>
                        {
                            show3 && (<div>
                                <div className="skills__list grid">
                                    <div className="skills__data">
                                        <div className="skills__titles">
                                            <h3 className="skills__name" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>Photoshop</h3>
                                            <span className="skills__number" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>85%</span>
                                        </div>
                                        <div className="skills__bar">
                                            <span className="skills_percentage skills__photoshop"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="skills__list grid">
                                    <div className="skills__data">
                                        <div className="skills__titles">
                                            <h3 className="skills__name" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>XD</h3>
                                            <span className="skills__number" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>90%</span>
                                        </div>
                                        <div className="skills__bar">
                                            <span className="skills_percentage skills__xd"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="skills__list grid">
                                    <div className="skills__data">
                                        <div className="skills__titles">
                                            <h3 className="skills__name" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>Illustrator</h3>
                                            <span className="skills__number" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>80%</span>
                                        </div>
                                        <div className="skills__bar">
                                            <span className="skills_percentage skills__illustrator"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="skills__list grid">
                                    <div className="skills__data">
                                        <div className="skills__titles">
                                            <h3 className="skills__name" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>InDesign</h3>
                                            <span className="skills__number" style={{
                                                color: darkMode ? "white" : "black",

                                            }}>55%</span>
                                        </div>
                                        <div className="skills__bar">
                                            <span className="skills_percentage skills__indesign"></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            )
                        }
                    </div>


                </div>
            </div>
        </section>
    )
}

export default Skills;