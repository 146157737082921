import React from "react";
import {SiLinkedin} from 'react-icons/si';
import {SiGithub} from 'react-icons/si';
 import {SiCodesandbox} from 'react-icons/si';

const HeaderSocials = () => {
    return (
        <div className="header__socials">
            <a href="https://www.linkedin.com/in/ring-sumlut-1400351a8/" target="_blank"><SiLinkedin /></a>
            <a href="https://github.com/ringsumlut" target="_blank"><SiGithub /></a>
           {/* <a href="https://codesandbox.io" target="_blank"><SiCodesandbox /></a> */}
        </div>
    )

}

export default HeaderSocials;