import React, { useContext } from "react";
import Header from './components/header/Header';
import Nav from './components/nav/Nav';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Portfolio from './components/portfolio/Portfolio';
import Services from './components/services/services';
import PortfolioList from "./components/portfolioList/PortfolioList";
import Project from './components/project/Project';
import ProjectCard from './components/project/ProjectCard';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Toggle from "./components/toggle/Toggle";
import { ThemeContext } from "./context"

const App = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div
            style={{
                backgroundColor: darkMode ? "#222" : "white",
                color: darkMode && "white",
            }}>

            <Toggle />
            <Header />
            <Nav />
            <About />
            <Skills />
            <Portfolio />
            <Contact />
            <Footer />

        </div>
    )
}

export default App;