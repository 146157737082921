import React, { useRef, useContext, useState } from "react";
import './contact.css'
import { FaEnvelope } from 'react-icons/fa'
import { RiMessengerFill } from 'react-icons/ri'
import emailjs from 'emailjs-com'
import { ThemeContext } from "../../context"

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_9kzay89', 'template_hutzefj', form.current, 'y-X_GFdhnOZPfJHXu')
        e.target.reset();
    };

    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    const [emailhover, setemailHover] = useState(false);

    const toggleEmailHover = () => {
        setemailHover(!emailhover);
    };

    const [messengerhover, setmessengerHover] = useState(false);

    const toggleMessengerHover = () => {
        setmessengerHover(!messengerhover);
    };

   
    const emailStyle = {
        // color: darkMode ? "white" : "white",
        color: 'white',
        textAlign: "center",
        transition: "color 0.2s ease-in-out", // add transition for smoother effect
        cursor: "pointer", // add cursor to indicate clickable element
    };

    const emailHoverStyle = {
        color: darkMode ? "white" : "black",
    };
    const messengerStyle = {
        // color: darkMode ? "yellow" : "white",
        color: 'white',
        textAlign: "center",
        transition: "color 0.2s ease-in-out", // add transition for smoother effect
        cursor: "pointer", // add cursor to indicate clickable element
    };

    const messengerHoverStyle = {
        color: darkMode ? "white" : "black",
    };

    return (
        <section id="contact">
            <h5 style={{
                color: darkMode ? "white" : "black",

            }}>Get In Touch</h5>
            <h2>Contact Me</h2>
            <div className="container contact__container">
                <div className="contact__options" style={{
                    color: darkMode ? "white" : "black",

                }}>
                    <article className="contact__option"
                        style={emailhover ? { ...emailStyle, ...emailHoverStyle } : emailStyle}
                        onMouseEnter={toggleEmailHover}
                        onMouseLeave={toggleEmailHover}>

                        <FaEnvelope className="contact__option-icon" />
                        <h4>Email</h4>
                        <h5>ringsumlut@gmail.com</h5>
                        <a href="mailto:ringsumlut@gmail.com" target="_blank">Send a Message</a>

                    </article>

                    <article className="contact__option"
                        style={messengerhover ? { ...messengerStyle, ...messengerHoverStyle } : messengerStyle}
                        onMouseEnter={toggleMessengerHover}
                        onMouseLeave={toggleMessengerHover}>
                        <RiMessengerFill className="contact__option-icon" />
                        <h4>Messenger</h4>
                        <h5>Ring Sutjat Sumlut</h5>
                        <a href="https://m.me/100010140187420" target="_blank">Send a Message</a>

                    </article>

                    {/* <article className="contact__option">
                        <FaEnvelope className="contact__option-icon" />
                        <h4>Email</h4>
                        <h5>ringsumlut@gmail.com</h5>
                        <a href="3174419153" target="_blank">Send a Message</a>

                    </article> */}
                </div>
                <form ref={form} onSubmit={sendEmail} >
                    <input type="text" name='name' placeholder='Your Name' style={{
                        color: darkMode ? "white" : "black",

                    }} required />
                    <input type="email" name='email' placeholder='Your Email' style={{
                        color: darkMode ? "white" : "black",

                    }} required />
                    <textarea name='message' rows='7' placeholder='Your Message' style={{
                        color: darkMode ? "white" : "black",

                    }} required></textarea>
                    <button type="submit" className="btn btn-primary">Send Message</button>
                </form>
            </div>

        </section>
    )
}

export default Contact;