import React, { useContext, useState } from "react";
import './portfolio.css';
import IMG0 from '../../assets/explore.png'
import IMG1 from '../../assets/guildgaming.png'
import IMG2 from '../../assets/music.png'
import IMG3 from '../../assets/gbcc.png'
import IMG4 from '../../assets/valhalla.png'
import IMG5 from '../../assets/supershine.png'
import IMG6 from '../../assets/travel.png'
import IMG7 from '../../assets/keurig.png'
import IMG8 from '../../assets/junglecook.png'
import IMG9 from '../../assets/eventsy.png'
import { ThemeContext } from "../../context"
import Carousel from 'react-elastic-carousel'
import { Autoplay } from "swiper";
const data = [
    {
        id: 0,
        image: IMG0,
        title: 'Explorer Travel Mobile App',
        demo: "https://expo.dev/@ringsumlut/lets-go-then?serviceType=classic&distribution=expo-go",
        source: "",
        language: 'React Native, CSS, API'
    },
    {
        id: 1,
        image: IMG1,
        title: 'Guild Gaming',
        demo: "https://www.guildgaming.gg/map",
        source: "",
        language: 'React, CSS, Python, MapBox'
    },

    {
        id: 2,
        image: IMG2,
        title: 'Mp3 Player',
        demo: "https://61980.csb.app/",
        source: "https://codesandbox.io/s/relaxed-noyce-61980",
        language: 'HTML, CSS, JavaScript, React'
    },
    {
        id: 3,
        image: IMG3,
        title: 'GBCC Church Website',
        demo: "https://gbccindianapolis.org/",
        source: "",
        language: 'WordPress'
    },
    {
        id: 4,
        image: IMG4,
        title: 'Valhalla Online Store',
        demo: "https://dev-valahalla.pantheonsite.io/",
        source: "",
        language: 'WordPress'
    },
    {
        id: 5,
        image: IMG5,
        title: 'Super Shine Auto Body Shop',
        demo: "https://dev-super-shine-auto-body-and-paint.pantheonsite.io",
        source: "",
        language: 'WordPress'
    },
    {
        id: 6,
        image: IMG6,
        title: 'Travel UI',
        demo: "",
        source: "https://github.com/ringsumlut/Jungle-Cook",
        language: 'HTML, CSS, JavaScript'
    },
    {
        id: 7,
        image: IMG7,
        title: 'Keurig Web UI',
        demo: "",
        source: "https://github.com/ringsumlut/Jungle-Cook",
        language: 'HTML, CSS, JavaScript'
    },

    {
        id: 8,
        image: IMG8,
        title: 'Jungle Cook UI',
        demo: "",
        source: "https://github.com/ringsumlut/Jungle-Cook",
        language: 'HTML, CSS, JavaScript'
    },
    {
        id: 9,
        image: IMG9,
        title: 'Eventsy',
        demo: "",
        source: "https://github.com/ringsumlut/final-project-Eventsy/tree/master",
        language: 'HTML, SASS, JavaScript'
    },
]

const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1200, itemsToShow: 4, itemsToScroll: 4 },
]

const Portfolio = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    const [hoveredItem, setHoveredItem] = useState(null);

    const handleItemEnter = (itemId) => {
        setHoveredItem(itemId);
    };
    const handleItemLeave = () => {
        setHoveredItem(null);
    };
    const titleStyle = {
        color: darkMode ? "white" : "yellow",
        textAlign: "center",
        transition: "color 0.2s ease-in-out", // add transition for smoother effect
        cursor: "pointer", // add cursor to indicate clickable element
    };

    const titleHoverStyle = {
        color: "orange", // change color on hover
    };

    return (
        <section id="portfolio">
            <h5 style={{
                color: darkMode ? "white" : "black",
            }}>My Recent Works</h5>
            <h2>Projects</h2>
            {/* <div className="container portfolio__container"> */}
            <Carousel className="container portfolio__container" breakPoints={breakPoints} showArrows>
                {
                    data.map(({ id, image, title, demo, source, language }) => {
                        return (
                            <article className="portfolio__item"
                                key={id}
                                style={{ ...titleStyle, ...(hoveredItem === id && titleHoverStyle) }}
                                onMouseEnter={() => handleItemEnter(id)}
                                onMouseLeave={handleItemLeave}
                            >
                                <div className="portfolio__item-image">
                                    <img src={image} atl={title} />
                                </div>
                                <h3>{title}</h3>
                                <p>Skills : {language}</p>
                                <div className=".portfolio__item-cta">
                                    {demo && source ? (
                                        <>
                                            <a href={demo} className="btn" target='_blank'>Live Demo</a>
                                            <a href={source} className="btn" target='_blank'>Source</a>
                                        </>
                                    ) : demo ? (
                                        <a href={demo} className="btn" target='_blank'>Live Demo</a>
                                    ) : source ? (
                                        <a href={source} className="btn" target='_blank'>source</a>
                                    ) : null}
                                </div>
                            </article>
                        )
                    })
                }
            </Carousel>
        </section>
    )
}

export default Portfolio;