import React from "react";
import './footer.css';
import { RiFacebookCircleFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";
import { RiSnapchatFill } from "react-icons/ri";

const  Footer = () => {
    return (
        <footer>
            <a href="#" className="footer__logo">RING</a>

            <ul className="permalinks">
                <li><a href="#">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#skills">Skills</a></li>
                <li><a href="#portfolio">Projects</a></li>
                <li><a href="#contact">Contact</a></li>
            </ul>

            <div className="footer__socials">
                <a href="https://www.facebook.com/profile.php?id=100010140187420" target="_blank"><RiFacebookCircleFill/></a>
                <a alt='blank' href="https://www.instagram.com/ring_dev/?hl=en" target="_blank"><RiInstagramFill/></a>
                {/* <a href="https://t.snapchat.com/krudigC9" ><RiSnapchatFill/></a> */}
            </div>

            <div className="footer__copyright">
                <small>&copy; RING. All Rights Reserved</small>
            </div>
        </footer>
    )
}

export default Footer;