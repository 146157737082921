import React from "react";
import './nav.css';
import { AiOutlineHome } from 'react-icons/ai'
import { AiOutlineUser } from 'react-icons/ai'
import { AiOutlineMail } from 'react-icons/ai'
import { MdWorkOutline } from 'react-icons/md'
import { FaTools, Fabars, FaTimes } from 'react-icons/fa'
import { MdOutlineCastForEducation } from 'react-icons/md'
import { Link } from 'react-scroll'


import { useState } from "react";

const Nav = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click)
    const closeMenu = () => setClick(false)
    return (
        // <nav>
        //     <Link to="/" spy={true} smooth={true} offset={-100} duration={500}  onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}> <AiOutlineHome /> </Link>
        //     <Link to="about" spy={true} smooth={true} offset={-100} duration={500} onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''} > <AiOutlineUser /> </Link>
        //     <Link to="skills" spy={true} smooth={true} offset={-100} duration={500} onClick={() => setActiveNav('#skills')} className={activeNav === '#skills' ? 'active' : ''} > <MdOutlineCastForEducation /> </Link>
        //     <Link to="portfolio" spy={true} smooth={true} offset={-100} duration={500}  onClick={() => setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''} ><MdWorkOutline />  </Link>
        //     <Link to="services" spy={true} smooth={true} offset={-100} duration={500} onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''} ><FaTools />  </Link>
        //     <Link to="contact" spy={true} smooth={true} offset={-100} duration={500} onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''} ><AiOutlineMail />  </Link>

        // </nav>

        <div className="header">
            <nav className="navbar">
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className="nav-item">
                        <Link to="hero" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}> <AiOutlineHome /> </Link>
                    </li>
                </ul>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className="nav-item">
                        <Link to="about" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}> <AiOutlineUser /> </Link>
                    </li>
                </ul>
                {/* <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className="nav-item">
                        <Link to="skills" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}> <MdOutlineCastForEducation /> </Link>
                    </li>
                </ul> */}
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className="nav-item">
                        <Link to="skills" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}> <FaTools /> </Link>
                    </li>
                </ul>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className="nav-item">
                        <Link to="portfolio" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}> <MdWorkOutline /> </Link>
                    </li>
                </ul>

                <ul className={click ? "nav-menu active" : "nav-menu"}>
                    <li className="nav-item">
                        <Link to="contact" spy={true} smooth={true} offset={-100} duration={500} onClick={closeMenu}> <AiOutlineMail /> </Link>

                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Nav;