import React, { useContext } from "react";
import './about.css';
import RingRing3 from "../../assets/ringring3.jpg";
import { FaUniversity } from "react-icons/fa";
import { IoSchoolOutline } from "react-icons/io5";
import { BiCodeAlt } from "react-icons/bi";
import { ThemeContext } from "../../context"

const About = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    return (
        <section id="about">

            <h2>About Me</h2>

            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={RingRing3} alt="RingRing3" />
                    </div>
                </div>

                <div className="about__content">
                    <div className="about__cards" >
                        <article className="about__card" style={{
                            color: darkMode ? "white" : "black",

                        }}>
                            <FaUniversity style={{ fontSize: 20 }} />
                            <h5 style={{
                                color: darkMode ? "white" : "black",

                            }}>School</h5>
                            <small style={{
                                color: darkMode ? "white" : "black",

                            }}>Indiana University–Purdue University Indianapolis</small>
                        </article>

                        <article className="about__card" style={{
                            color: darkMode ? "white" : "black",

                        }}>
                            <IoSchoolOutline style={{ fontSize: 20 }} />
                            <h5 style={{
                                color: darkMode ? "white" : "black",

                            }}>Major</h5>
                            <small style={{
                                color: darkMode ? "white" : "black",

                            }}>Media Arts & Science</small>
                        </article>

                        <article className="about__card" style={{
                            color: darkMode ? "white" : "black",

                        }}>
                            <BiCodeAlt style={{ fontSize: 20 }} />
                            <h5 style={{
                                color: darkMode ? "white" : "black",

                            }}>Specialized</h5>
                            <small style={{
                                color: darkMode ? "white" : "black",

                            }}>Web Design & Development</small>
                        </article>

                    </div>
                    <div>
                        <p style={{
                            color: darkMode ? "white" : "black",

                        }}>To secure a challenging position as a Software Developer where I can leverage my skills and experience in
                            designing and developing innovative software solutions that exceed customer expectations., while constantly learning
                            and expanding my knowledge in the field. I am committed to delivering high-quality work, collaborating effectively
                            with cross-functional teams, and staying up to date with emerging technologies and best practices to develop
                            innovative solutions that meet business objectives.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;