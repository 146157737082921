import React, { useContext } from "react";
import './header.css';
import CTA from './CTA';
import RingRing from '../../assets/ringring.png';
import HeaderSocials from "./HeaderSocials";
import { ThemeContext } from "../../context"




const Header = () => {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    return (
        <header className="hero" id="hero">
            <div className="container header__container">
                <div className="card">
                    <h3 style={{
                        color: darkMode ? "white" : "black",

                    }}>
                        &#123;Hello I'm&#125;
                    </h3>
                    <h1 style={{
                        color: darkMode ? "white" : "black",

                    }}>
                        Ring Sumlut
                    </h1>
                    <h4 className="text-light" style={{
                        color: darkMode ? "white" : "black",

                    }}>
                        &lt; Software Developer /&gt;
                    </h4>
                </div>
                <CTA />
                <HeaderSocials />
                <div className="RingRing">
                    <img src={RingRing} alt="RingRing" />
                </div>

                <a href="#contact" className="scroll__down">Scroll Down</a>
            </div>
        </header>
    )
}

export default Header;